export interface Label{
    cat_id:string
    cat_name: string
    lable_id: string
    lable_name: string
}

export default interface Topic{
    topic_id:string,
    topic_name:string,
    topic_lead:string,
    topic_date_create:string,
    url:string,
    lable: {
        [key: string]: {
            [key:string]: Label
        }
    },
    media_main: {
        data: {
            media_desc:string,
            media_name: {
                big:string,
                medium:string,
                original:string,
                small:string,
            }
        }
    }|null,
}

export function instanceOfLabel(object:any):object is Label{
    return (
        object instanceof Object &&
        'cat_id' in object &&
        'cat_name' in object &&
        'lable_id' in object &&
        'lable_name' in object
    );
}

export function instanceOfTopic(object:any):object is Topic{
    const simple = (
        object instanceof Object &&
        'topic_id' in object &&
        'topic_name' in object &&
        'topic_lead' in object &&
        'topic_date_create' in object &&
        'url' in object &&
        'lable' in object &&
        'media_main' in object &&
        (
            object.media_main === null || (
                'data' in object.media_main &&
                'media_desc' in object.media_main.data &&
                'media_name' in object.media_main.data &&
                'big' in object.media_main.data.media_name &&
                'medium' in object.media_main.data.media_name &&
                'original' in object.media_main.data.media_name &&
                'small' in object.media_main.data.media_name
            )
        )
    );

    if(!simple){
        return false;
    }

    const cats = Object.values<any>(object.lable);

    for(const l of cats){
        const lables = Object.values<any>(l);

        for(const l of lables){
            if(!instanceOfLabel(l)){
                return false;
            }
        }
    }

    return true;
}

export interface TopicLink{
    topic_id:string,
}