import "./Single.css";
import React, {DragEvent} from "react";
import Topic, {instanceOfTopic} from "../../../entity/Topic";
import Messages from "../../../translations/Messages";
import dragTransfer from "../../../helper/DragTransfer";
import {Type} from "../../Dashboard/Board/Slot/Slot";
import Board from "../../../entity/Board";

interface Props{
    topic: Topic,
    boards: Array<Board>
}

class Single extends React.Component<Props, any>{

    constructor(props:Props) {
        super(props);

        this.onDrugStart = this.onDrugStart.bind(this);
    }

    public onDrugStart(event:DragEvent){
        dragTransfer.set<Topic>(event, Type.Topic, this.props.topic);
    }

    render() {

        let containsInBoard = false;

        for(const board of this.props.boards){
            for(const content of board.contents){
                if(instanceOfTopic(content)){
                    if(content.topic_id.toString() === this.props.topic.topic_id.toString()){
                        containsInBoard = true
                        break
                    }
                }
            }

            if(containsInBoard){
                break
            }
        }

        const classList = ['Topics-Single'];

        if(containsInBoard){
            classList.push('Topics-Single----contains')
        }

        return (
            <a
                className={classList.join(' ')}
                href={this.props.topic.url}
                target="_blank"
                rel="noreferrer"
                draggable="true"
                onDragStart={this.onDrugStart}
            >
                {this.props.topic.media_main !== null
                    ? <img
                        className="Topics-Single--poster"
                        src={this.props.topic.media_main.data.media_name.small}
                        alt={Messages.c.Topics.poster}
                        draggable="false"
                    />
                    : <div className="Topics-Single--poster Topics-Single--poster--empty" draggable="false"/>
                }
                <span className="Topics-Single--name" draggable="false">
                    {this.props.topic.topic_name}
                </span>
            </a>
        );
    }
}

export default Single;