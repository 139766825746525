import React, {ChangeEvent} from "react";

interface Props{
    name:string,
    placeholder: string,
    value: string,
    change:(e:ChangeEvent<HTMLTextAreaElement>) => void
}

export default class Row extends React.Component<Props, {}>{
    render() {
        return (
            <label className="Navigator-Externals-Single--row">
                    <span className="Navigator-Externals-Single--row-placeholder">
                        {this.props.placeholder}
                    </span>
                <textarea
                    className="Navigator-Externals-Single--row-input"
                    value={this.props.value}
                    onChange={this.props.change}
                    name={this.props.name}
                />
            </label>
        );
    }
}