import "./Application.css";
import React from "react";
import Loading from "../Loading/Loading";
import Authenticate from "../Authenticate/Authenticate";
import Page from "../Page/Page";
import authenticateChecker from "../../../helper/AuthenticateChecker";

interface Props{

}

interface State{
    isAuthenticateCheck:boolean,
    isAuthenticate:boolean,
    phpSessionId:string,
}

class App extends React.PureComponent<Props, State>{
    constructor(props:Props) {
        super(props);

        this.state = {
            isAuthenticateCheck: false,
            isAuthenticate: false,
            phpSessionId: '',
        };

        authenticateChecker.onAuthenticateChange = (isAuthenticate:boolean) => {
            this.setState({isAuthenticate});
        };

        this.handleAuthenticateChange = this.handleAuthenticateChange.bind(this);
    }

    handleAuthenticateChange(isAuthenticate: boolean):void{
        this.setState({isAuthenticate});
    }

    async componentDidMount() {
        await authenticateChecker.isAuthenticate();
        this.setState({isAuthenticateCheck: true});
    }

    render() {
        if(!this.state.isAuthenticateCheck){
            return (<Loading/>);
        }

        if(!this.state.isAuthenticate){
            return (<Authenticate onAuthenticate={this.handleAuthenticateChange}/>);
        }

        return (<Page/>);
    }
}

export default App;