import "./Page.css";
import React from "react";
import Dashboard from "../../Dashboard/Dashboard";
import Board, {BoardLink} from "../../../entity/Board";
import Navigator from "../../Navigator/Navigator";
import External, {ExternalLink} from "../../../entity/External";
import query from "../../../helper/Query";
import Action from "../../../helper/Action";
import {instanceOfTopic, TopicLink} from "../../../entity/Topic";
import FixedLengthArray from "../../../helper/FixedLengthArray";
import {Snackbar} from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

interface State{
    boards:Array<Board>,
    externals:Array<External>,
    open: boolean;
    statusMessage: boolean;
}

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

export default class Page extends React.PureComponent<{}, State>{

    constructor(props:{}) {
        super(props);

        this.state = {
            boards: [],
            externals: [],
            open: false,
            statusMessage: false
        };

        this.changeBoards = this.changeBoards.bind(this);
        this.changeExternals = this.changeExternals.bind(this);
        this.save = this.save.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleClose (event?: React.SyntheticEvent, reason?: string){
        if (reason === 'clickaway') {
          return;
        }
    
        this.setState({
            open: false
        });
      };

    handleClick(){
        this.setState({
            open:true
        })
    }

    async componentDidMount() {
        const response = await query.send<State>(Action.LOAD);

        if(response.data === null){
            return;
        }

        this.setState(response.data);
    }

    changeBoards(boards:Array<Board>):void{
        this.setState({boards});
    }

    changeExternals(externals:Array<External>):void{
        this.setState({externals});
    }

    async save():Promise<void>{

        const boardsLink:Array<BoardLink> = [];

        for(const board of this.state.boards){

            const contents:FixedLengthArray<TopicLink|ExternalLink|null, 5> = board.contents.map((content) => {
                if(instanceOfTopic(content)){
                    return {
                        topic_id: content.topic_id
                    }
                }else{
                    return content;
                }
            }) as FixedLengthArray<TopicLink|ExternalLink|null, 5>;

            boardsLink.push({
                id: board.id,
                title: board.title,
                type: board.type,
                contents: contents,
                fillEmpty: board.fillEmpty,
                connectPrev: board.connectPrev,
            })
        }

        const body = new FormData();
        body.append('boards', JSON.stringify(boardsLink));
        body.append('externals', JSON.stringify(this.state.externals));
        const res = await query.send<null>(Action.SAVE, body);
        this.handleClick()
        console.log(res)
        if(res.success === true){
            this.setState({
                statusMessage: true
            })
        }
    }

    render(){

        const msg = this.state.statusMessage ? <Alert onClose={this.handleClose} severity="success">
                                                                Ваши данные успешно отправлены и сохранены на сайте
                                                            </Alert>: 
                                                            <Alert onClose={this.handleClose} severity="error">
                                                                Ваши данные не отправлены, произошла Ошибка!
                                                            </Alert>;

        return (
            <div className="base-Page">
                <div className="base-Page--part base-Page--dashboard">
                    <Dashboard
                        externals={this.state.externals}
                        boards={this.state.boards}
                        changeBoards={this.changeBoards}
                    />
                </div>
                <div className="base-Page--part base-Page--navigator">
                    <Navigator
                        externals={this.state.externals}
                        changeExternals={this.changeExternals}
                        save={this.save}
                        boards={this.state.boards}
                    />
                </div>
                <Snackbar open={this.state.open} autoHideDuration={4000} onClose={this.handleClose}>
                        {msg}
                </Snackbar>
            </div>
        );
    }
}