import React from "react";
import "./Loading.css";
import Messages from "../../../translations/Messages";

class Loading extends React.PureComponent<{}, {}>{
    render() {
        return (
            <div className="base-Loading">
                <div className="base-Loading--spinner"/>
                <div className="base-Loading--message">{Messages.c.base.Loading.text}</div>
            </div>
        );
    }
}

export default Loading;