import "./Ad.css";
import React from "react";
import Messages from "../../../translations/Messages";

const TITLE_CHAR = '█';

function getRandomArbitrary(min:number, max:number) {
    return Math.random() * (max - min) + min;
}

const generateTitle = ():string => {
    const countWords:number = getRandomArbitrary(5, 10);
    const words = [];

    for(let i = 0; i <= countWords; i++){
        const wordLength = getRandomArbitrary(4, 10);
        words.push(TITLE_CHAR.repeat(wordLength));
    }

    return words.join('       ');
};

interface Props{
    title: string,
}

export default class Ad extends React.PureComponent<Props, any>{
    render() {
        return (
            <div className="Dashboard-Ad">
                <div className="Dashboard-Ad--title">
                    {this.props.title}
                </div>
                <div className="Dashboard-Ad--list">
                    {Array.from((new Array(5)).keys()).map((_, index) => {
                        return (
                            <div className="Dashboard-Ad--item" key={index}>
                                <div className="Dashboard-Ad--item-poster"/>
                                <div className="Dashboard-Ad--item-title">
                                    {generateTitle()}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}