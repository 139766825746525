
export class EnumData<EnumType>{
    public readonly keys:Array<string>;
    public readonly values:Array<EnumType>;

    constructor(keys:Array<string>, values:Array<EnumType>) {
        this.keys = keys;
        this.values = values
    }
}

export default function enumData<EnumType>(e: {[s in string|number]: any}):EnumData<EnumType>{
    const all:Array<any> = Object.values(e);
    const keys: Array<string> = all.slice(0, all.length / 2) as Array<string>;
    const values: Array<EnumType> = all.slice(all.length / 2) as Array<EnumType>;
    return new EnumData<EnumType>(keys, values);
}