import "./Authenticate.css";
import React, {KeyboardEvent, ChangeEvent} from "react";
import Message from "../Message/Message";
import Messages from "../../../translations/Messages";
import authenticateChecker from "../../../helper/AuthenticateChecker";

interface States{
    form: {
        login: string,
        password: string,
    },
    error:string|null,
}

class Authenticate extends React.Component<any, States>{

    private readonly loginInput:React.RefObject<HTMLInputElement>;
    private readonly passwordInput:React.RefObject<HTMLInputElement>;

    constructor(props:any) {
        super(props);
        this.state = {
            form: {
                login: '',
                password: '',
            },
            error: null
        };

        this.loginInput = React.createRef<HTMLInputElement>();
        this.passwordInput = React.createRef<HTMLInputElement>();

        this.onChange = this.onChange.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        this.loginInput.current?.focus();
    }

    onChange(event:ChangeEvent<HTMLInputElement>){
        const target = event.currentTarget;
        const name = target.getAttribute('name') as null|"login"|"password";
        const value = target.value;
        const form = this.state.form;

        if(name === null || !(name in form)){
            return;
        }

        form[name] = value;
        this.setState({form});
    }

    onKeyDown(event:KeyboardEvent<HTMLInputElement>):void{
        const target = event.currentTarget;
        const name = target.getAttribute('name') as null|"login"|"password";

        if(event.key === 'Enter'){
            if(name === 'login'){
                this.passwordInput.current?.focus();
                event.preventDefault();
            } else if(name === 'password'){
                this.onSubmit().then(() => {});
                event.preventDefault();
            }
        } else if(event.key === 'Tab'){
            if(name === 'login'){
                this.passwordInput.current?.focus();
                event.preventDefault();
            } else if(name === 'password'){
                this.loginInput.current?.focus();
                event.preventDefault();
            }
        }
    }

    async onSubmit(){

        const success = await authenticateChecker.authenticate(this.state.form.login, this.state.form.password);
        this.setState({form: {login: '', password: ''}});

        if(success){
            return;
        }

        this.setState({error: Messages.c.base.Authenticate.error});
        this.loginInput.current?.focus();
    }

    render() {
        return (
            <Message title={Messages.c.base.Authenticate.title}>
                <div className="base-Authenticate">
                    <label className="base-Authenticate--row">
                        <input
                            name="login"
                            className="base-Authenticate--row-input"
                            value={this.state.form.login}
                            onChange={this.onChange}
                            onKeyDown={this.onKeyDown}
                            type="text"
                            ref={this.loginInput}
                        />
                        <span className="base-Authenticate--row-placeholder">
                            {Messages.c.base.Authenticate.login}
                        </span>
                    </label>
                    <label className="base-Authenticate--row">
                        <input
                            name="password"
                            className="base-Authenticate--row-input"
                            value={this.state.form.password}
                            onChange={this.onChange}
                            onKeyDown={this.onKeyDown}
                            type="password"
                            ref={this.passwordInput}
                        />
                        <span className="base-Authenticate--row-placeholder">
                            {Messages.c.base.Authenticate.password}
                        </span>
                    </label>
                    <button
                        className="base-Authenticate--submit"
                        onClick={this.onSubmit}
                    >
                        {Messages.c.base.Authenticate.submit}
                    </button>
                </div>
            </Message>
        );
    }

}

export default Authenticate;