import "./Search.css";
import React, {ChangeEvent, KeyboardEvent, WheelEvent} from "react";
import Topic from "../../../entity/Topic";
import query from "../../../helper/Query";
import Action from "../../../helper/Action";
import Messages from "../../../translations/Messages";
import Single from "../Single/Single";
import Board from "../../../entity/Board";

interface State{
    search:string,
    topics:Array<Topic>
    process:boolean,
}

interface Props{
    boards: Array<Board>
}

export default class Search extends React.PureComponent<Props, State>{
    constructor(props:any) {
        super(props);
        this.state = {
            search: '',
            topics: [],
            process: false,
        };

        this.searchTextChange = this.searchTextChange.bind(this);
        this.search = this.search.bind(this);
        this.onSearchInputPress = this.onSearchInputPress.bind(this);
    }

    searchTextChange(event:ChangeEvent<HTMLInputElement>){
        const target = event.currentTarget;
        this.setState({search: target.value})
    }

    async search():Promise<void>{

        this.setState({
            topics: [],
            process: true
        });

        const body = new FormData();
        body.append('q', this.state.search);
        const response = await query.send<Array<Topic>>(Action.SEARCH, body);

        this.setState({
            topics: response.data ?? [],
            process: false,
        });
    }

    onSearchInputPress(event:KeyboardEvent<HTMLInputElement>){
        if(event.key === 'Enter'){
            event.preventDefault();
            this.search().then();
        }
    }

    horizontalScroll(e: WheelEvent){
            let container = document.querySelector('.Search--topics')
            let containerScrollPosition = document.querySelector('.Search--topics')!.scrollLeft
            container!.scrollTo({
                top: 0,
                left: containerScrollPosition + e.deltaY
            })
    }

    render() {

        const submitClassList = ['Search--form-submit'];

        if(this.state.process){
            submitClassList.push('Search--form-submit--process');
        }

        return (
            <div className="Search">
                <div className="Search--form">
                    <input
                        className="Search--form-input"
                        onChange={this.searchTextChange}
                        onKeyDown={this.onSearchInputPress}
                        placeholder={Messages.c.Topics.placeholder}
                    />
                    <button
                        className={submitClassList.join(' ')}
                        onClick={this.search}
                        title={Messages.c.Topics.search}
                    />
                </div>
                <div className="Search--topics" onWheel={window.screen.availWidth <= 1300 ? (e: WheelEvent) => this.horizontalScroll(e): () => {}}>
                    {this.state.topics.map((topic:Topic) => (
                        <Single
                            key={topic.topic_id}
                            topic={topic}
                            boards={this.props.boards}
                        />
                    ))}
                </div>
            </div>
        );
    }
}