import "./Single.css";
import React, {ChangeEvent, DragEvent} from "react";
import Messages from "../../../../translations/Messages";
import External, {ExternalLink} from "../../../../entity/External";
import {NAME, POSTER, Type} from "../../../Dashboard/Board/Slot/Slot";
import Message from "../../../base/Message/Message";
import Row from "./Row/Row";
import dragTransfer from "../../../../helper/DragTransfer";
import query from "../../../../helper/Query";
import Action from "../../../../helper/Action";

export interface Props{
    external:External,
    changeExternal:(external:External) => void,
    removeExternal:() => void,
}

export interface State{
    isOpen:boolean,
    uploadImageError: null|string|JSX.Element,
}

interface UploadImage{
    url: string,
}

interface UploadError{
    message: string
}

function instanceOfUploadImage(object:any):object is UploadImage{
    return (
        object instanceof Object &&
        'url' in object
    );
}

function instanceOfUploadError(object:any):object is UploadError{
    return (
        object instanceof Object &&
        'message' in object
    );
}

export const IMAGE_SIZE_LIMIT = 1024 * 200;

export default class Single extends React.Component<Props, State>{

    constructor(props:Props) {
        super(props);

        this.state = {
            isOpen: false,
            uploadImageError: null,
        };

        this.onDrugStart = this.onDrugStart.bind(this);
        this.edit = this.edit.bind(this);
        this.remove = this.remove.bind(this);
        this.close = this.close.bind(this);
        this.change = this.change.bind(this);
        this.uploadImage = this.uploadImage.bind(this);
        this.removeImage = this.removeImage.bind(this);
        this.closeUploadError = this.closeUploadError.bind(this);
    }

    edit():void{
        this.setState({isOpen:true});
    }

    close():void{
        this.setState({isOpen:false});
    }

    change(event:ChangeEvent<HTMLTextAreaElement>):void{
        const value = event.target.value;
        const name = event.target.name as Exclude<keyof External, "icons"|"id">;
        this.props.changeExternal({...this.props.external, [name]: value});
    }

    onDrugStart(event:DragEvent):void{
        dragTransfer.set<ExternalLink>(event, Type.ExternalLink, {
            id: this.props.external.id
        });
    }

    async uploadImage(event:ChangeEvent<HTMLInputElement>):Promise<void>{

        if(event.target.disabled){
            return;
        }

        const files = event.target.files;

        if(files === null || files.length === 0){
            return;
        }

        const file = files[0];
        event.target.value = '';

        if(file.size > IMAGE_SIZE_LIMIT){
            const message = Messages.c.Navigator.External.imageFat(IMAGE_SIZE_LIMIT, file.size);
            this.setState({
                uploadImageError: message
            })
            return;
        }

        event.target.disabled = true;
        const body = new FormData();
        body.append('file', file);
        const response = await query.send<UploadImage, string|UploadError>(Action.UPLOAD_IMAGE, body);
        event.target.disabled = false;

        if(instanceOfUploadImage(response.data)){
            const external = this.props.external;
            external.image = response.data.url;
            this.props.changeExternal(external);
            return;
        }

        if(response.data === null){
            return;
        }

        if(instanceOfUploadError(response.data)){
            this.setState({
                uploadImageError: response.data.message
            })
            return
        }

        this.setState({
            uploadImageError: response.data.toString(),
        })
    }

    removeImage(){
        this.props.changeExternal({...this.props.external, image: ''});
    }

    closeUploadError(){
        this.setState({
            uploadImageError: null,
        })
    }

    remove():void{
        this.props.removeExternal();
    }

    private get name():string{
        if(this.props.external.name.trim() === ''){
            return NAME;
        }
        return this.props.external.name
    }

    private get description():string{
        return this.props.external.description
    }

    private get image():string{
        if(this.props.external.image.trim() === ''){
            return POSTER;
        }
        return this.props.external.image
    }

    errorJsx():JSX.Element{

        if(this.state.uploadImageError === null){
            return (<></>);
        }

        return (
            <Message title={Messages.c.Navigator.External.uploadError}>
                <div className="Navigator-Externals-Single--uploadError">
                    <div className="Navigator-Externals-Single--uploadError-message">
                        {this.state.uploadImageError}
                    </div>
                    <button
                        className="Navigator-Externals-Single--uploadError-close"
                        onClick={this.closeUploadError}
                    >
                        {Messages.c.Navigator.External.close}
                    </button>
                </div>
            </Message>
        );
    }

    editJsx():JSX.Element{

        if(!this.state.isOpen){
            return (<></>);
        }

        return (
            <Message title={Messages.c.Navigator.External.editTitle}>
                <div className="Navigator-Externals-Single--form">
                    <Row
                        placeholder={Messages.c.Navigator.External.placeholder.description}
                        value={this.props.external.description}
                        name='description'
                        change={this.change}
                    />
                    <Row
                        placeholder={Messages.c.Navigator.External.placeholder.name}
                        value={this.props.external.name}
                        name='name'
                        change={this.change}
                    />
                    <div className="Navigator-Externals-Single--row">
                        <div className="Navigator-Externals-Single--row-placeholder">
                            {Messages.c.Navigator.External.placeholder.image}
                        </div>
                        <div className="Navigator-Externals-Single--row-file">
                            <input
                                onChange={this.uploadImage}
                                type="file"
                                className="Navigator-Externals-Single--row-file-input"
                                placeholder={Messages.c.Navigator.External.selectImage}
                            />
                            <button
                                className="Navigator-Externals-Single--row-file-input-remove"
                                onClick={this.removeImage}
                            >
                                {Messages.c.Navigator.External.placeholder.imageRemove}
                            </button>
                        </div>
                        <input
                            name="image"
                            value={this.props.external.image}
                            type="text"
                            disabled={true}
                            className="Navigator-Externals-Single--row-input"
                        />
                    </div>
                    <Row
                        placeholder={Messages.c.Navigator.External.placeholder.lead}
                        value={this.props.external.lead}
                        name='lead'
                        change={this.change}
                    />
                    <Row
                        placeholder={Messages.c.Navigator.External.placeholder.category}
                        value={this.props.external.category}
                        name='category'
                        change={this.change}
                    />
                    <Row
                        placeholder={Messages.c.Navigator.External.placeholder.url}
                        value={this.props.external.url}
                        name='url'
                        change={this.change}
                    />
                    <button
                        className="Navigator-Externals-Single--close"
                        onClick={this.close}
                    >
                        {Messages.c.Navigator.External.close}
                    </button>
                </div>
            </Message>
        );
    }

    render() {

        const classList = ['Navigator-Externals-Single'];

        if(this.state.isOpen){
            classList.push('Navigator-Externals-Single----edit');
        }

        return (
            <div className={classList.join(' ')}>
                <div
                    className="Navigator-Externals-Single--view"
                    onDragStart={this.onDrugStart}
                    draggable="true"
                >
                    <div className="Navigator-Externals-Single--view-image">
                        <img
                            className="Navigator-Externals-Single--view-image-img"
                            src={this.image}
                            alt={this.name}
                        />
                    </div>
                    <div className="Navigator-Externals-Single--view-name">
                        {this.name}
                    </div>
                    <div className="Navigator-Externals-Single--view-description">
                        {this.description}
                    </div>
                    <div className="Navigator-Externals-Single--buttons">
                        <button
                            className="Navigator-Externals-Single--button Navigator-Externals-Single--remove"
                            onClick={this.remove}
                            title={Messages.c.Navigator.External.remove}
                        />
                        <button
                            className="Navigator-Externals-Single--button Navigator-Externals-Single--edit"
                            onClick={this.edit}
                            title={Messages.c.Navigator.External.edit}
                        />
                    </div>
                </div>
                {this.editJsx()}
                {this.errorJsx()}
            </div>
        );
    }
}