export default interface External{
    id:number,
    description:string,
    url:string,
    name: string,
    lead: string,
    category: string,
    image: string,
    icons: Array<'video'|'image'>,
}

export function instanceOfExternal(object:any):object is External{
    return (
        object instanceof Object &&
        'id' in object &&
        'description' in object &&
        'url' in object &&
        'name' in object &&
        'lead' in object &&
        'category' in object &&
        'image' in object &&
        'icons' in object &&
        object.icons instanceof Array
    );
}

export interface ExternalLink{
    id: number
}

export function instanceOfExternalLink(object:any):object is ExternalLink{
    return (
        object instanceof Object &&
        'id' in object &&
        !('description' in object)
    );
}