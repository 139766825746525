import "./Navigator.css";
import React from "react";
import enumData from "../../helper/enumData";
import Messages from "../../translations/Messages";
import Last from "./Last/Last";
import Search from "./Search/Search";
import Externals from "./Externals/Externals";
import External from "../../entity/External";
import authenticateChecker from "../../helper/AuthenticateChecker";
import Board from "../../entity/Board";


export enum Section{
    Last,
    Search,
    Externals,
}

interface State{
    section:Section;
    saveProcess: boolean;
}

interface Props{
    boards: Array<Board>
    externals:Array<External>,
    changeExternals:(externals:Array<External>) => void,
    save:() => Promise<void>
}

export default class Navigator extends React.PureComponent<Props, State>{

    constructor(props:Props) {
        super(props);

        this.state = {
            section: Section.Last,
            saveProcess: false
        };

        this.changeSection = this.changeSection.bind(this);
        this.save = this.save.bind(this);
    }
    


    async save():Promise<void>{
        if(this.state.saveProcess){
            return;
        }
        this.setState({saveProcess: true});
        await this.props.save();
        this.setState({saveProcess: false});
    }

    async exit():Promise<void> {
        await authenticateChecker.logout();
    }

    changeSection(section:Section):void{
        this.setState({section:section});
    }

    buttonsJsx():JSX.Element{
        return (
            <div className="Navigator--section-buttons">
                {enumData<Section>(Section).values.map((s) => {

                    const classList:Array<string> = [
                        'Navigator--section-button',
                        'Navigator--section-button--' + Section[s],
                    ];

                    if(s === this.state.section){
                        classList.push('Navigator--section-button--current');
                        classList.push('current');
                    }

                    return (
                        <button
                            key={s.toString()}
                            className={classList.join(' ')}
                            onClick={() => this.changeSection(s)}
                        >
                            {Messages.c.Navigator.section[s]}
                        </button>
                    );
                })}
            </div>
        );
    }

    sectionsJsx():JSX.Element{
        return (
            <div className="Navigator--sections">
                {enumData<Section>(Section).values.map((section):JSX.Element => {
                    const classList = ['Navigator--section',];

                    if(section === this.state.section){
                        classList.push('Navigator--section--current');
                    }

                    const content = (():JSX.Element => {
                        switch (section){
                            case Section.Last: return (<Last boards={this.props.boards}/>);
                            case Section.Search: return (<Search boards={this.props.boards}/>);
                            case Section.Externals: return (
                                <Externals
                                    externals={this.props.externals}
                                    changeExternals={this.props.changeExternals}
                                />
                            );
                        }
                    })();

                    return (
                        <div className={classList.join(' ')} key={section}>
                            {content}
                        </div>
                    );
                })}
            </div>
        );
    }


    render() {

        const saveClassList = ['Navigator--save'];

        if(this.state.saveProcess){
            saveClassList.push('Navigator--save--progress')
        }

        return (
            <div className="Navigator">
                {this.buttonsJsx()}
                {this.sectionsJsx()}
                <div className="Navigator--bottom">
                    <button
                        className="Navigator--exit"
                        onClick={this.exit}
                    >
                        {Messages.c.Navigator.exit}
                    </button>
                    <button
                        className={saveClassList.join(' ')}
                        onClick={this.save}
                    >
                        {Messages.c.Navigator.save}
                    </button>
                </div>
            </div>
        );
    }
}