import config from "../Config";
import Action from "./Action";
import authenticateChecker from "./AuthenticateChecker";


export interface Response<T>{
    success:boolean,
    data: T|null,
}


class Query{
    public async send<T, E = null>(action:Action, body:FormData|null = null):Promise<Response<T|E|null>>{

        if(body === null){
            body = new FormData();
        }

        body.append('action', action);

        if(authenticateChecker.sessionId !== null){
            body.append('session_id', authenticateChecker.sessionId);
        }

        const response = await fetch(config.API, {
            method: 'post',
            credentials: "include",
            mode: "cors",
            body,
        });

        if(!response.ok){
            console.log(response.status, response.statusText);

            return {
                success: false,
                data: null,
            };
        }

        

        return await response.json() as Response<T|E|null>;
    }
}

const query = new Query();
export default query;