import FixedLengthArray from "../helper/FixedLengthArray";
import Topic, {TopicLink} from "./Topic";
import {ExternalLink} from "./External";

export default interface Board{
    id: string,
    type: BoardType,
    title: string,
    fillEmpty: boolean,
    connectPrev: boolean,
    contents: FixedLengthArray<Topic|ExternalLink|null, 5>
}

export interface BoardLink{
    id: string,
    type: BoardType,
    title: string,
    contents: FixedLengthArray<TopicLink|ExternalLink|null, 5>
    fillEmpty: boolean,
    connectPrev: boolean,
}

export enum BoardType{
    Empty,
    Single,
    Two,
    Three,
    Fore,
    Five,
    SingleMedia,
    TwoMedia,
    ThreeMedia,
    ForeMedia,
    ThreeMediaExtend,
}