import React from "react";
import ReactDOM from "react-dom";
import "./Message.css";

declare var window:Window & typeof globalThis

interface Props{
    title:string,
}

class Message extends React.PureComponent<Props, {}>{

    private el: HTMLDivElement;
    private modal: HTMLElement;

    constructor(props:Props) {
        super(props);

        const modal = window.document.getElementById('modal');

        if(modal === null){
            throw new Error('Html element #modal not exist')
        }

        this.el = window.document.createElement('div');
        this.modal = modal;
    }

    componentDidMount() {
        this.modal.appendChild(this.el);
    }

    componentWillUnmount() {
        this.modal.removeChild(this.el);
    }

    render() {
        return ReactDOM.createPortal(
            (
                <div className="base-Message">
                    <div className="base-Message--form">
                        <div className="base-Message--title">{this.props.title}</div>
                        <div className="base-Message--body">{this.props.children}</div>
                    </div>
                </div>
            ),
            this.el
        );
    }
}

export default Message;