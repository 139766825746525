import "./Slot.css";
import React, {DragEvent} from "react";
import Topic, {instanceOfTopic, Label} from "../../../../entity/Topic";
import External, {ExternalLink, instanceOfExternal, instanceOfExternalLink} from "../../../../entity/External";
import dragTransfer from "../../../../helper/DragTransfer";
import Messages from "../../../../translations/Messages";

interface Props {
    externals: Array<External>,
    content: Topic|ExternalLink|null,
    onChange: (content: Topic|ExternalLink|null) => void,
    template: Template
}

interface State {
    content: Topic|External|null,
}

export enum Template {
    Card,
    Line,
    LineBig,
    LineExtend,
    CardExtend,
    CardMedia
}

export enum Type{
    Topic = 'Topic',
    ExternalLink = 'ExternalLink',
}

export const NAME = 'Rick Astley: For the song by Barry White, see Never, Never Gonna Give Ya Up.';
export const URL = 'https://youtu.be/dQw4w9WgXcQ';
export const CATEGORY = 'Eternal hits';
export const POSTER = 'https://coub-anubis-a.akamaized.net/coub_storage/coub/simple/cw_timeline_pic/97481a32cb5/738c0685b904e4bf02639/1484476791_image.jpg';
export const LEAD =
    '"Never Gonna Give You Up" is the debut song rec' +
    'orded by English singer and songwriter Rick Ast' +
    'ley, released as his debut single on 27 July 19' +
    '87. It was written and produced by Stock Aitken' +
    ' Waterman, and was released as the first single' +
    ' from Astley\'s debut album, Whenever You Need ' +
    'Somebody (1987).'
;

let transferContent:Topic|ExternalLink|null = null;

export default class Slot extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            content: null
        };

        this.onDragOver = this.onDragOver.bind(this);
        this.onDragLeave = this.onDragLeave.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.onDrugStart = this.onDrugStart.bind(this);
        this.onDrugEnd = this.onDrugEnd.bind(this);
        this.clearSlot = this.clearSlot.bind(this);
    }

    getDragEventContent(event: DragEvent):Topic|External|null{
        const isTopic = dragTransfer.has(event, Type.Topic);
        const isExternal = dragTransfer.has(event, Type.ExternalLink);
        let content:Topic|External|null = null;

        if (isTopic || isExternal) {
            event.preventDefault();
            event.stopPropagation();

            if(isTopic){
                content = dragTransfer.get<Topic>(event, Type.Topic);
            }
            if(isExternal){
                content = dragTransfer.get<External>(event, Type.ExternalLink);
            }
        }

        return content;
    }

    onDragOver(event: DragEvent) {
        const content = this.getDragEventContent(event);

        if(content !== null){
            this.setState({content});
        }
    }

    onDragLeave(event:DragEvent):void{
        event.stopPropagation();
        event.preventDefault();
        this.setState({content: null});
    }

    onDrop(event: DragEvent): void {
        transferContent = this.props.content;
        const content = this.getDragEventContent(event);

        if(content !== null){
            this.props.onChange(content);
            this.setState({content: null});
        }
    }

    public onDrugStart(event:DragEvent): void{

        if(this.props.content === null){
            return;
        }

        if(instanceOfExternal(this.props.content)){
            dragTransfer.set<External>(event, Type.ExternalLink, this.props.content);
        }

        if(instanceOfTopic(this.props.content)){
            dragTransfer.set<Topic>(event, Type.Topic, this.props.content);
        }
    }

    public onDrugEnd():void{
        this.props.onChange(transferContent);
    }

    public clearSlot(){
        this.props.onChange(null);
    }

    private get content(): Topic | External | null {
        let content:Topic|ExternalLink|null = this.props.content;

        if(instanceOfExternalLink(content)){
            for(const external of this.props.externals){
                if(external.id === content.id){
                    return external;
                }
            }

            return null;
        }

        return content;
    }

    private get isOverContent():boolean{
        return this.state.content !== null;
    }

    private get url(): string {
        if(instanceOfTopic(this.content)){
            return this.content.url;
        }
        if(instanceOfExternal(this.content)){
            return this.content.url;
        }
        return URL;
    }

    private get poster(): string | null {
        if(instanceOfTopic(this.content)){
            return this.content.media_main?.data.media_name.medium ?? POSTER;
        }
        if(instanceOfExternal(this.content)){
            return this.content.image === '' ? POSTER : this.content.image;
        }

        return POSTER;
    }

    private get alt(): string {
        if(instanceOfTopic(this.content)){
            return this.content.media_main?.data.media_desc ?? POSTER;
        }
        if(instanceOfExternal(this.content)){
            return this.content.name;
        }
        return '';
    }

    private get category(): Label | null {
        if(instanceOfTopic(this.content)){
            if (this.content === null) {
                return null;
            }

            const categories = this.content.lable['1'] ?? [];
            const keys = Object.keys(categories);

            if (keys.length === 0) {
                return null;
            }

            return categories[keys[0]];
        }

        return null;
    }

    private get categoryName(): string {
        if(instanceOfTopic(this.content)){
            return this.category?.cat_name ?? CATEGORY;
        }
        if(instanceOfExternal(this.content)){
            return this.content.category;
        }
        return CATEGORY;
    }

    private get name(): string {
        if(instanceOfTopic(this.content)){
            return this.content.topic_name;
        }
        if(instanceOfExternal(this.content)){
            return this.content.name;
        }
        return NAME;
    }

    private get lead(): string {
        if(instanceOfTopic(this.content)){
            return this.content.topic_lead;
        }
        if(instanceOfExternal(this.content)){
            return this.content.lead;
        }
        return LEAD;
    }

    private get icons():Array<string>{
        if(instanceOfTopic(this.content)){
            const formats = this.content.lable['5'] ?? [];
            const formatIds = Object.keys(formats);
            const icons: Array<string> = [];

            for (const formatId of formatIds) {

                let icon = null;

                switch (formatId) {
                    case '17':
                        icon = 'photo';
                        break;
                    case '18':
                        icon = 'video';
                        break;
                }

                if (icon === null) {
                    continue;
                }

                icons.push(icon);
            }

            return icons;
        }
        if(instanceOfExternal(this.content)){
            return this.content.icons;
        }
        return [];
    }

    private get template(): string {
        return Template[this.props.template];
    }

    private get nameJsx(): JSX.Element {
        return (
            <a
                className={"Dashboard-Board-Slot--" + this.template + "-name"}
                href={this.url} target="_blank" rel="noreferrer"
            >
                {this.name}
            </a>
        );
    }

    private get categoryJsx(): JSX.Element {
        return (
            <span className={"Dashboard-Board-Slot--" + this.template + "-category"}>
                {this.categoryName}
            </span>
        );
    }

    private get iconsJsx(): JSX.Element | null {
        const icons: Array<JSX.Element> = [];

        for (const icon of this.icons) {
            icons.push(<div className={"Dashboard-Board-Slot--" + this.template + "-icon-" + icon}/>);
        }

        if (icons.length === 0) {
            return null;
        }

        return (
            <div className={"Dashboard-Board-Slot--" + this.template + "-icons"}>
                {icons.reduce((icons: null | JSX.Element, icon: JSX.Element) => {
                    return icons === null ? icon : <>{icons}{icon}</>;
                }, null)}
            </div>
        );
    }

    private get posterJsx(): JSX.Element {
        return (
            <div className={"Dashboard-Board-Slot--" + this.template + "-poster"}>
                {this.poster !== null &&
                <img
                    className={"Dashboard-Board-Slot--" + this.template + "-poster-img"}
                    src={this.poster}
                    alt={this.alt}
                />
                }
                {this.iconsJsx}
            </div>
        );
    }

    private get leadJsx(): JSX.Element {
        return (
            <div className={"Dashboard-Board-Slot--" + this.template + "-lead"}>
                {this.lead}
            </div>
        );
    }

    private templateJsx(content: JSX.Element): JSX.Element {

        const classList = ['Dashboard-Board-Slot'];

        if(this.isOverContent){
            classList.push('Dashboard-Board-Slot----over');
        }
        if(this.props.content !== null){
            classList.push('Dashboard-Board-Slot----exist');

            if(instanceOfTopic(this.props.content)){
                classList.push('Dashboard-Board-Slot----exist-topic');
            }
            if(instanceOfExternalLink(this.props.content)){
                classList.push('Dashboard-Board-Slot----exist-external');
            }
        } else {
            classList.push('Dashboard-Board-Slot----empty');
        }

        return (
            <div
                className={classList.join(' ')}
                onDragOver={this.onDragOver}
                onDragEnter={this.onDragOver}
                onDragLeave={this.onDragLeave}
                onDrop={this.onDrop}
                onDragStart={this.onDrugStart}
                onDragEnd={this.onDrugEnd}
                draggable="true"
            >
                <div className="Dashboard-Board-Slot--empty">
                    {Messages.c.Dashboard.Board.Slot.empty}
                </div>
                <button
                    className="Dashboard-Board-Slot--clear"
                    onClick={this.clearSlot}
                />
                <div className={"Dashboard-Board-Slot--" + this.template}>
                    {content}
                </div>
            </div>
        );
    }

    render() {
        const getContent = ():JSX.Element => {
            switch (this.props.template) {
                case Template.Card:
                case Template.CardMedia:
                    return <>
                        {this.posterJsx}
                        {this.nameJsx}
                        {this.categoryJsx}
                    </>;
                case Template.Line:
                case Template.LineBig:
                case Template.LineExtend:
                    return <>
                        {this.categoryJsx}
                        {this.nameJsx}
                    </>;
                case Template.CardExtend:
                    return <>
                        {this.posterJsx}
                        {this.nameJsx}
                        {this.categoryJsx}
                        {this.leadJsx}
                    </>;
            }
        }

        return this.templateJsx(getContent());
    }
}