import "./Externals.css";
import React from "react";
import External from "../../../entity/External";
import Messages from "../../../translations/Messages";
import Single from "./Single/Single";

interface Props{
    externals:Array<External>,
    changeExternals:(externals:Array<External>) => void,
}

export default class Externals extends React.PureComponent<Props, {}>{

    constructor(props:Props) {
        super(props);
        this.addExternal = this.addExternal.bind(this);
        this.changeExternalCallback = this.changeExternalCallback.bind(this);
        this.removeExternalCallback = this.removeExternalCallback.bind(this);
    }

    addExternal():void{
        let id = 1;
        for (const external of this.props.externals){
            if(external.id >= id){
                id = external.id + 1;
            }
        }

        const external:External = {
            id: id,
            icons: [],
            lead: '',
            name: '',
            category: '',
            image: '',
            url: '',
            description: '',
        };

        this.props.changeExternals([external, ...this.props.externals]);
    }

    changeExternalCallback(index:number){
        return (external:External):void => {
            const externals = [...this.props.externals];
            externals[index] = external;
            this.props.changeExternals(externals);
        };
    }

    removeExternalCallback(index:number){
        return ():void => {
            const externals = [...this.props.externals];
            externals.splice(index, 1);
            this.props.changeExternals(externals);
        };
    }

    render() {
        return (
            <div className="Navigator-Externals">
                <div className="Navigator-Externals--top">
                    <button
                        className="Navigator-Externals--add"
                        onClick={this.addExternal}
                    >
                        {Messages.c.Navigator.External.add}
                    </button>
                </div>
                <div className="Navigator-Externals--list">
                    {this.props.externals.map((external:External, index:number) => {
                        return (
                            <Single
                                key={external.id}
                                external={external}
                                changeExternal={this.changeExternalCallback(index)}
                                removeExternal={this.removeExternalCallback(index)}
                            />
                        );
                    })}
                </div>
            </div>
        );
    }
}
