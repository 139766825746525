import "./Dashboard.css";
import React from "react";
import External from "../../entity/External";
import {default as BoardComponent} from "./Board/Board";
import Board, {BoardType} from "../../entity/Board";
import Banner from "./Banner/Banner";
import Ad from "./Ad/Ad";
import Messages from "../../translations/Messages";

interface Props{
    externals:Array<External>,
    boards: Array<Board>,
    changeBoards:(boards:Array<Board>) => void,
}

export default class Dashboard extends React.PureComponent<Props, {}>{
    constructor(props:Props) {
        super(props);

        this.onRemoveCallback = this.onRemoveCallback.bind(this);
        this.addBoard = this.addBoard.bind(this);
        this.onChangeCallback = this.onChangeCallback.bind(this);
        this.onUpCallback = this.onUpCallback.bind(this);
        this.onDownCallback = this.onDownCallback.bind(this);
    }

    public onChangeCallback(index:number):((board:Board) => void){
        return (board:Board):void => {
            const boards = [...this.props.boards];
            boards[index] = board;
            this.props.changeBoards(boards);
        };
    }

    public onRemoveCallback(index:number):(() => void){
        return ():void => {
            const boards = [...this.props.boards];
            boards.splice(index, 1);
            this.props.changeBoards(boards);
        }
    }

    public addBoard(index:number):void{
        const boards:Array<Board> = [...this.props.boards];
        boards.splice(index, 0, {
            type:BoardType.Empty,
            title: '',
            id: Math.random().toString(),
            contents: [null, null, null, null, null],
            connectPrev: false,
            fillEmpty: false,
        });

        this.props.changeBoards(boards);
    }

    public onUpCallback(index:number):(() => void){
        return ():void =>{
            if(index === 0){
                return;
            }
            const boards = [...this.props.boards];
            const board = boards.splice(index, 1)[0];
            boards.splice(index-1, 0, board);
            this.props.changeBoards(boards);
        }
    }

    public onDownCallback(index:number):(() => void){
        return ():void =>{
            if(index === this.props.boards.length - 1){
                return;
            }
            const boards = [...this.props.boards];
            const board = boards.splice(index, 1)[0];
            boards.splice(index+1, 0, board);
            this.props.changeBoards(boards);
        }
    }

    render() {

        let connectPrev = false;

        return (
            <div className="Dashboard">
                <div className="Dashboard--list">
                    {this.props.boards.map((board:Board, index:number) => {
                        const boardPrev = index === 0 ? null : this.props.boards[index -1];
                        const classList = ['Dashboard--list-add'];

                        connectPrev = (
                            board.connectPrev ||
                            (
                                boardPrev !== null &&
                                !boardPrev.connectPrev &&
                                boardPrev.title.trim() === '' &&
                                board.title.trim() === ''
                            )
                        );

                        if(connectPrev){
                            classList.push('Dashboard--list-add--white')
                        }

                        return (
                            <React.Fragment key={board.id}>
                                <button className={classList.join(' ')} onClick={() => this.addBoard(index)}/>
                                <React.Fragment>
                                    <BoardComponent
                                        board={board}
                                        externals={this.props.externals}
                                        onChange={this.onChangeCallback(index)}
                                        onRemove={this.onRemoveCallback(index)}
                                        onUp={this.onUpCallback(index)}
                                        onDown={this.onDownCallback(index)}
                                        banner={(index === 3 || index === 5) && <Banner/>}
                                    />
                                </React.Fragment>
                                                {index === 1 && <div className="Dashboard--list-AdBanner">
                                                    <Ad title={Messages.c.Dashboard.Ad.companyArticles}/>
                                                    <Banner/>
                                                </div>}
                            </React.Fragment>
                        );
                    })}
                    <button className="Dashboard--list-add" onClick={() => this.addBoard(this.props.boards.length)}/>
                </div>
            </div>
        );
    }
}