import "./Last.css";
import React, { WheelEvent } from "react";
import query from "../../../helper/Query";
import Action from "../../../helper/Action";
import Topic from "../../../entity/Topic";
import Single from "../Single/Single";
import Board from "../../../entity/Board";

export const UPDATE_LAST_TOPICS_INTERVAL = 500000000;

interface State{
    topics:Array<Topic>
}
interface Props{
    boards: Array<Board>
}

class Last extends React.PureComponent<Props, State>{

    private updateTopicListInterval:NodeJS.Timeout|null = null;
    private progress:boolean = false;
    clientHeight: any;
    scrollLeft: any;

    constructor(props:any) {
        super(props);
        this.state = {
            topics: [],
        };

        this.updateLastTopicList = this.updateLastTopicList.bind(this);
    }

    async updateLastTopicList():Promise<void>{

        if(this.progress){
            return;
        }

        this.progress = true;
        const response = await query.send<Array<Topic>>(Action.LAST);
        this.progress = false;

        if(!(response.data instanceof Array)){
            return;
        }

        this.setState({topics: response.data});

        this.progress = false;
    }

    public async componentDidMount() {
        await this.updateLastTopicList();
        this.updateTopicListInterval = setInterval(async () => {
            await this.updateLastTopicList();
        }, UPDATE_LAST_TOPICS_INTERVAL)
    }

    componentWillUnmount() {
        if(this.updateTopicListInterval !== null){
            clearInterval(this.updateTopicListInterval);
        }
    }

    horizontalScroll(e: WheelEvent){
            let container = document.querySelector('.Topics')
            let containerScrollPosition = document.querySelector('.Topics')!.scrollLeft
            container!.scrollTo({
                top: 0,
                left: containerScrollPosition + e.deltaY
            })
    }

    render() {
        return (
            <div className="Topics" onWheel={window.screen.availWidth <= 1300 ? (e: WheelEvent) => this.horizontalScroll(e): () => {}}>
                {this.state.topics.map((topic:Topic) => (
                    <Single
                        key={topic.topic_id}
                        topic={topic}
                        boards={this.props.boards}
                    />
                ))}
            </div>
        );
    }
}

export default Last;