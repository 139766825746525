import "./Banner.css";
import React from "react";
import Messages from "../../../translations/Messages";

export default class Banner extends React.PureComponent<any, any>{
    render() {
        return (
            <div className="Dashboard-Banner">
                {Messages.c.Dashboard.Banner.placeholder}
            </div>
        );
    }
}