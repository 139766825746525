import {DragEvent} from "react";

interface CacheItem{
    name:string,
    value:any,
}

class DragTransfer{

    private cache:Array<CacheItem> = [];

    private static castFormat(type:string):string{
        return `application/dashboard/${type.toLowerCase()}`;
    }

    set<T>(event:DragEvent, type:string, value:T):void{
        const format = DragTransfer.castFormat(type);
        let isUpdateCache = false;

        for(const cacheItem of this.cache){
            if(cacheItem.name !== format){
                continue;
            }

            cacheItem.value = value;
            isUpdateCache = true;
        }
        if(!isUpdateCache){
            this.cache.push({
                name: format,
                value: value,
            });
        }

        event.dataTransfer.setData(format, '');
    }

    get<T>(event:DragEvent, type:string):T|null{
        const format = DragTransfer.castFormat(type);

        if(!event.dataTransfer.types.includes(format)) {
            return null;
        }

        for(let index = 0; index < this.cache.length; index++) {
            const cacheItem = this.cache[index];

            if(cacheItem.name !== format){
                continue;
            }

            return cacheItem.value;
        }

        return null;
    }

    has(event:DragEvent, type:string):boolean{
        const format = DragTransfer.castFormat(type);
        return event.dataTransfer.types.includes(format);
    }
}

const dragTransfer = new DragTransfer();

export default dragTransfer;