import {BoardType} from "../entity/Board";
import {Section} from "../c/Navigator/Navigator";

const Messages = {
    c: {
        base: {
            Loading: {
                text: 'Подождите...',
            },
            Authenticate: {
                title: 'Авторизация',
                login: 'Логин',
                password: 'Пароль',
                submit: 'Вход',
                error: 'Неверный логин или пароль',
            },
        },
        Topics: {
            placeholder: 'url|id|тект из статьи',
            search: 'Поиск',
            last: 'Последние опубликованные',
            poster: 'Главное изображение'
        },
        Dashboard:{
            Board:{
                Slot: {
                    empty: 'Пусто',
                },
                type: {
                    [BoardType.Empty]: 'Пустой',
                    [BoardType.Single]: 'Одна',
                    [BoardType.Two]: 'Две',
                    [BoardType.Three]: 'Три',
                    [BoardType.Fore]: 'Четыре',
                    [BoardType.Five]: 'Пять',
                    [BoardType.SingleMedia]: 'Одна (с фото)',
                    [BoardType.TwoMedia]: 'Две (с фото)',
                    [BoardType.ThreeMedia]: 'Три (с фото)',
                    [BoardType.ForeMedia]: 'Четыре (с фото)',
                    [BoardType.ThreeMediaExtend]: 'Три (с фото, первая 2 слота)',
                } as Partial<Record<BoardType, string>>,
                typePlaceholder: 'Тип блока',
                emptySlot: 'Слот пустой, перенесите в него новость чтобы заполнить его',
                empty: 'Это тип блока не показывает новости.',
                title: 'Заголовок блока',
                remove: 'Удалить',
                up: 'Поднять выше',
                down: 'Спустить ниже',
                fillEmptySlotsLastNews: 'Заполнять пустые слоты',
                connectWithPrevBoard: 'Объединить с предыдущим блоком',
            },
            Banner: {
                placeholder: 'Тут будет баннер',
            },
            Ad: {
                companyArticles: 'Блок "Новости компаний"',
                voting2021: 'Выборы-2021',
            }
        },
        Navigator: {
            section: {
                [Section.Last]: 'Последние',
                [Section.Search]: 'Поиск',
                [Section.Externals]: 'Вставки',
            } as Partial<Record<Section, string>>,
            save: 'Сохранить',
            exit: 'Выход',
            External:{
                add: 'Добавить новую вставку',
                remove: 'Удалить',
                edit: 'Редактировать',
                editTitle: 'Редактирование',
                close: 'Закрыть',
                uploadError: 'Ошибка загрузки изображения',
                selectImage: 'Выбрать изображение',
                imageFat: (max:number, current:number):JSX.Element => {
                    return (
                        <div>
                            {
                                `Изображение слишком большое. ` +
                                `Максимальный размер ${max} байт. ` +
                                `Выбраное вами изображение имеет размер ${current} байт. ` +
                                `Для сжатия изображения можете воспользоваться этим сайтом:`
                            }
                            <a
                                href="https://tinypng.com/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                tinypng.com
                            </a>
                        </div>
                    );
                },
                placeholder: {
                    description: 'Описание (видно только вам)',
                    name: 'Заголовок',
                    lead: 'Лид (используются не во всех вариантах оформления)',
                    category: 'Категория',
                    url: 'Ссылка',
                    image: 'Фото/Превью',
                    imageRemove: 'Удалить превью',
                    icons: {
                        title: 'Иконки (используются не во всех вариантах оформления)',
                        video: 'Видео',
                        image: 'Фото',
                    },
                },
            }
        },
    }
};

export default Messages;