import "./Board.css";
import React, {ChangeEvent} from "react";
import {default as BoardEntity, BoardType} from "../../../entity/Board";
import Messages from "../../../translations/Messages";
import Topic from "../../../entity/Topic";
import External, {ExternalLink} from "../../../entity/External";
import Slot, {Template} from "./Slot/Slot";
import enumData from "../../../helper/enumData";

interface Props{
    externals:Array<External>,
    board: BoardEntity,
    onChange: (board:BoardEntity)=> void,
    onRemove: ()=> void,
    onUp:() => void,
    onDown:() => void,
    banner:false|JSX.Element,
}

export type IndexTopic = 0|1|2|3|4;

export default class Board extends React.PureComponent<Props, any>
{

    constructor(props:Props) {
        super(props);
        this.onChangeEvent = this.onChangeEvent.bind(this);
        this.onChangeSlot = this.onChangeSlot.bind(this);
        this.changeType = this.changeType.bind(this);
        this.onChangeFillEmpty = this.onChangeFillEmpty.bind(this);
        this.onChangeConnectPrev = this.onChangeConnectPrev.bind(this);
    }

    changeType(event:ChangeEvent<HTMLSelectElement>):void
    {
        const countOfChosenType = event.target.selectedOptions[0].getAttribute('data-count');
        if(countOfChosenType !== null){
            this.props.board.contents.forEach((item, i) => {
                if(i + 1 > +countOfChosenType){
                    return this.props.board.contents[i] = null;
                }
            })

            this.props.onChange({
                ...this.props.board,
                type: parseInt(event.target.value) as BoardType
            });
        }
    }

    changeName(title:string):void{
        this.props.onChange({...this.props.board, title: title});
    }

    onChangeEvent(index:IndexTopic, content:Topic|ExternalLink|null){
        const board = Object.assign(this.props.board);
        board.contents[index] = content;
        this.props.onChange(board);
    }

    onChangeFillEmpty(event:ChangeEvent<HTMLInputElement>){
        this.props.onChange({
            ...this.props.board,
            fillEmpty: event.currentTarget.checked
        });
    }

    onChangeConnectPrev(event:ChangeEvent<HTMLInputElement>){
        this.props.onChange({
            ...this.props.board,
            connectPrev: event.currentTarget.checked
        });
    }

    onChangeSlot(index:IndexTopic){
        return (content:Topic|ExternalLink|null):void => {
            this.onChangeEvent(index, content);
        }
    }

    topicsJsx():JSX.Element{

        const contents = this.props.board.contents;

        const slot = (index:IndexTopic, template:Template):JSX.Element => {
            return <Slot
                key={index}
                externals={this.props.externals}
                content={contents[index]}
                onChange={this.onChangeSlot(index)}
                template={template}
            />
        }

        const slots = (templates:Array<Template>):JSX.Element => {
            return (
                <div className={"Dashboard-Board--topics Dashboard-Board--topics--" + BoardType[this.props.board.type]}>
                    {templates.map((template, index) => {
                        return slot(index as IndexTopic, templates[index])
                    })}
                </div>
            );
        }

        switch (this.props.board.type){
            case BoardType.Empty: return <>{Messages.c.Dashboard.Board.empty}</>;
            case BoardType.Single: return slots([
                Template.LineExtend,
            ]);
            case BoardType.Two: return slots([
                Template.LineBig,
                Template.LineBig,
            ]);
            case BoardType.Three: return slots([
                Template.LineBig,
                Template.Line,
                Template.Line,
            ]);
            case BoardType.Fore: return slots([
                Template.Line,
                Template.Line,
                Template.Line,
                Template.Line,
            ]);
            case BoardType.Five: return slots([
                Template.CardMedia,
                Template.Line,
                Template.Line,
                Template.Line,
                Template.Line,
            ]);
            case BoardType.SingleMedia: return slots([
                Template.CardExtend,
            ]);
            case BoardType.TwoMedia: return slots([
                Template.CardMedia,
                Template.CardMedia,
            ]);
            case BoardType.ThreeMedia: return slots([
                Template.Card,
                Template.Card,
                Template.Card,
            ]);
            case BoardType.ForeMedia: return slots([
                Template.Card,
                Template.Card,
                Template.Card,
                Template.Card,
            ]);
            case BoardType.ThreeMediaExtend: return slots([
                Template.CardMedia,
                Template.Card,
                Template.Card,
            ]);
        }
    }

    countBoardTypeContents(type: string) 
    {
        const str =  type.toLowerCase();
        switch(true){
            case str.includes('одна'):
                return 1;
            case str.includes('две'):
                return 2;
            case str.includes('три'):
                return 3;
            case str.includes('четыре'):
                return 4;
            case str.includes('пять'):
                return 5;
            default:
                return 0;
        }
    }

    private get typesJsx():JSX.Element{
        return (
            <div className="Dashboard-Board--types-parent">
                <div className="Dashboard-Board--types-placeholder">
                    {Messages.c.Dashboard.Board.typePlaceholder}
                </div>
                <select
                    className={"Dashboard-Board--types"}
                    value={this.props.board.type}
                    onChange={this.changeType}
                >
                    {enumData<BoardType>(BoardType).values.map((type:BoardType) => {
                        return (
                            <option
                                key={type}
                                className="Dashboard-Board--type"
                                value={type}
                                data-count={this.countBoardTypeContents(Messages.c.Dashboard.Board.type[type] as string)}
                            >
                                {Messages.c.Dashboard.Board.type[type]}
                            </option>
                        );
                    })}
                </select>
            </div>
        );
    }

    controlsJsx():JSX.Element{
        return (
            <div className="Dashboard-Board--controls">
                <div className="Dashboard-Board--controls-checkboxes">
                    <label className="Dashboard-Board--controls-checkbox checkbox Dashboard-Board--controls-checkbox--fillEmpty">
                        <input
                            type="checkbox"
                            defaultChecked={this.props.board.fillEmpty}
                            onChange={this.onChangeFillEmpty}
                        />
                        <span className="Dashboard-Board--controls-checkbox-placeholder checkbox-placeholder">
                            {Messages.c.Dashboard.Board.fillEmptySlotsLastNews}
                        </span>
                    </label>
                    <label className="Dashboard-Board--controls-checkbox checkbox Dashboard-Board--controls-checkbox--connectPrev">
                        <input
                            type="checkbox"
                            defaultChecked={this.props.board.connectPrev}
                            onChange={this.onChangeConnectPrev}
                        />
                        <span className="Dashboard-Board--controls-checkbox-placeholder checkbox-placeholder">
                            {Messages.c.Dashboard.Board.connectWithPrevBoard}
                        </span>
                    </label>
                </div>
                <button
                    className="Dashboard-Board--controls-button Dashboard-Board--remove"
                    onClick={this.props.onRemove}
                    title={Messages.c.Dashboard.Board.remove}
                />
                {this.typesJsx}
                <button
                    className="Dashboard-Board--controls-button Dashboard-Board--up"
                    onClick={this.props.onUp}
                    title={Messages.c.Dashboard.Board.up}
                />
                <button
                    className="Dashboard-Board--controls-button Dashboard-Board--down"
                    onClick={this.props.onDown}
                    title={Messages.c.Dashboard.Board.down}
                />
            </div>
        );
    }

    render() {

        const classList = ['Dashboard-Board'];

        if(this.props.board.connectPrev){
            classList.push('Dashboard-Board----connectPrev');
        }

        return (
            <div className={classList.join(' ')}>
                <div className="Dashboard-Board-content">
                    <input
                        type="text"
                        value={this.props.board.title}
                        className="Dashboard-Board--title"
                        onChange={(event:ChangeEvent<HTMLInputElement>) => {
                            this.changeName(event.currentTarget.value)
                        }}
                        placeholder={Messages.c.Dashboard.Board.title}
                    />
                    {this.controlsJsx()}
                    {this.topicsJsx()}
                </div>
                {this.props.banner}
            </div>
        );
    }
}