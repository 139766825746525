enum Action{
    AUTHENTICATE_CHECK = 'authenticate-check',
    AUTHENTICATE = 'authenticate',
    LOGOUT = 'logout',
    SEARCH = 'search',
    TOPICS = 'topics',
    LAST = 'last',
    UPLOAD_IMAGE = 'upload-image',
    SAVE = 'save',
    LOAD = 'load',
}

export default Action;