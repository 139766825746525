
import Action from "./Action";
import query from "./Query";

interface Success{
    session_id:string,
}

function instanceOfSuccess(object:any):object is Success{
    return (
        object instanceof Object &&
        'session_id' in object
    );
}

class AuthenticateChecker{

    private _isAuthenticateSuccess:boolean = false;
    private set isAuthenticateSuccess(value:boolean){
        if(this._isAuthenticateSuccess !== value){
            if(this.onAuthenticateChange !== null){
                this.onAuthenticateChange(value);
            }
        }
        this._isAuthenticateSuccess = value;
    }
    private get isAuthenticateSuccess():boolean{
        return this._isAuthenticateSuccess;
    }

    private _sessionId:string|null = null;
    get sessionId():string|null{
        return this._sessionId;
    }

    public onAuthenticateChange:((isAuthenticate:boolean) => void)|null = null;

    async isAuthenticate(isNecessarily:boolean = false):Promise<boolean>{
        if(isNecessarily){
            this.isAuthenticateSuccess = false;
        }

        if(this.isAuthenticateSuccess){
            return true;
        }

        const data = await query.send<Success>(Action.AUTHENTICATE_CHECK);

        if(instanceOfSuccess(data.data)){
            this._sessionId = data.data.session_id;
        }

        this.isAuthenticateSuccess = data.success;
        return data.success;
    }

    async authenticate(login:string, password:string):Promise<boolean>
    {
        this.isAuthenticateSuccess = false;

        const body = new FormData();
        body.append('login', login);
        body.append('password', password);

        this.isAuthenticateSuccess = false;
        const data = await query.send<Success>(Action.AUTHENTICATE, body);

        if(instanceOfSuccess(data.data)){
            this._sessionId = data.data.session_id;
        }

        this.isAuthenticateSuccess = data.success;
        return data.success;
    }

    async logout():Promise<boolean>{
        const data = await query.send<null>(Action.LOGOUT);
        if(data.success){
            this.isAuthenticateSuccess = false;
            this._sessionId = null;
        }
        return data.success
    }
}

const authenticateChecker = new AuthenticateChecker();

export default authenticateChecker;